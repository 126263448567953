import './index.scss';

import get from 'lodash/get';

import Helmet from 'react-helmet';
import React from 'react';

import HomeSplash from '../components/HomeSplash';
import BlogPostPreview from '../components/BlogPostPreview';
import customerSupport from '../logos/customer-support.png';
import draft from './draft-icon.png';
import drg from './drg-logo.png';
import fanduel from './fanduel-logo.png';
import gameface from './gameface-logo.png';
import process from '../logos/process.png';
import teladoc from './teladoc-logo.png';
import training from '../logos/training.png';
import Layout from "../components/Layout";
import {graphql} from "gatsby";

class Index extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title');
    const posts = get(this, 'props.data.allMarkdownRemark.edges');

    return (
        <Layout>
          <section className="mb-4">
            <Helmet title={siteTitle} />

            <HomeSplash />
            <section className="bg-light text-center company-logos mb-4">
              {/* <h5 className="text-secondary font-weight-bold mb-4">Trusted By</h5> */}
              <div className="">
                <div className="mt-4 mb-4">
                  <img src={draft} alt="" className="partner-logo"/>
                  <img src={teladoc} alt="" className="partner-logo"/>
                  <img src={fanduel} alt="" className="partner-logo"/>
                  <img src={gameface} alt="" className="partner-logo"/>
                  <img src={drg} alt="" className="partner-logo"/>
                </div>
              </div>
            </section>
            <section className="text-center container mt-4 pt-4 mb-4 pb-4">
              <h3 className="text-center font-weight-bold mb-4">
                Our latest <span className="highlight">thoughts</span>.
              </h3>
              <p className="lead text-secondary pb-4">We're always trying to hone our craft. Software development takes practice, and constant learning. Here are some of the latest pieces we've worked on.</p>
              <div className="row">
                {posts.map(post => (
                    <BlogPostPreview
                        title={post.node.frontmatter.title}
                        excerpt={post.node.excerpt}
                        slug={post.node.fields.slug}
                    />
                ))}
              </div>
            </section>
            <section className="container services mt-4 mb-4 pb-4 border-top">
              <div className="service-title">
                <h3 className="text-center font-weight-bold mb-4">
                  Our <span className="highlight">holistic</span> approach.
                </h3>
                <p className="lead text-secondary text-center pb-4">
                  We've worked with companies across multiple business sectors. From large healthcare to small fantasy sports startups; we've seen it all, and we know how to build great products. We keep teams moving, and ship solutions <span className="highlight">fast</span>.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-4 col-12">
                  <div className="card border-0">
                    <div className="card-header border-0 bg-white d-flex flex-wrap justify-content-start align-items-center">
                      <img src={training}/>
                      <span className="h3 ml-4">Guidance</span>
                    </div>
                    <div className="card-body">
                      <ul>
                        <li>
                          Help junior developers level up quicker
                        </li>
                        <li>
                          Get your team up to speed on a new technology fast
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="card border-0">
                    <div className="card-header border-0 bg-white d-flex flex-wrap justify-content-start align-items-center">
                      <img src={customerSupport}/>
                      <span className="h3 ml-4">Staff to scale</span>
                    </div>
                    <div className="card-body">
                      <ul>
                        <li>
                          Get expertise and knowledge in your team immediately
                        </li>
                        <li>
                          Integrate seamlessly with your current workflow
                        </li>
                        <li>
                          Push out new features and products quicker than ever before
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="card border-0">
                    <div className="card-header border-0 bg-white d-flex flex-wrap justify-content-start align-items-center">
                      <img src={process}/>
                      <span className="h3 ml-4">Process</span>
                    </div>
                    <div className="card-body">
                      <ul>
                        <li>
                          Help your team move faster and deploy code quicker
                        </li>
                        <li>
                          let us help you create better processes in your organization
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <div className="text-center">
              <a href="/contact" className="btn btn-lg btn-outline-info">Learn More</a>
            </div>
          </section>
        </Layout>
    );
  };
};

export default Index

export const pageQuery = graphql`
  query PostPreviewQuery {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }, limit: 3) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            image {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 400)
              }
            }
          }
        }
      }
    }
  }
`
