import Logo from '../browntree-green-circle.png'
import './index.scss'

import React, { useEffect, useState } from 'react'
import { useInterval } from 'react-use'
import styled from 'styled-components'
import _ from 'underscore'

function Rain() {
  const DELAY = 100
  const emojis = ['🧠', '🦷', '🦴', '👀', '👁', '👅', '👄', '💋', '🩸']
  const [count, setCount] = useState([{ pixels: 0, key: 0, emoji: '' }])
  const [width, setWidth] = useState(50)

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setWidth(window.innerWidth)
    }
  }, [])

  useInterval(() => {
    if (count.length > 20) {
      count.shift()
    }
    const pixels = Math.floor(Math.random() * width)
    const key = pixels + Math.random() * 1000000
    const emoji = _.sample(emojis)
    count.push({ pixels, key, emoji })
    setCount([...count])
  }, DELAY)

  return (
    <SuperContainer>
      {count.map(({ pixels, key, emoji }) => (
        <VariablePlacement key={key} pixels={pixels}>
          {emoji}
        </VariablePlacement>
      ))}
    </SuperContainer>
  )
}

const HomeSplash = () => (
  <div className="jumbotron jumbotron-fluid home-splash">
    <JumbotronTextContainer>
      <div className="jumbotron-text container text-center">
        <Above>
          <a href="/">
            <img src={Logo} alt="" className="home-logo" />
          </a>
        </Above>
        <h1 className="display-4 font-weight-bold">Browntree Labs</h1>
        <p className="lead text-secondary pb-4">
          We've built products, scaled teams, shipped code, and learned a lot.
          Let's build something <span className="highlight">awesome</span>{' '}
          together, shall we?
        </p>
        <a href="/contact" className="btn btn-lg btn-outline-success mt-4 mb-4">
          Let's get Started
        </a>
      </div>
    </JumbotronTextContainer>
  </div>
)

export default HomeSplash

const JumbotronTextContainer = styled.div`
  z-index: 100;
`

const SuperContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`

const Above = styled.div`
  z-index: 111111;
  opacity: 1;
`

const VariablePlacement = styled.div`
  @keyframes falldown {
    0% {
      margin-top: 0;
    }
    100% {
      margin-top: 600px;
    }
  }

  font-size: 48px;
  opacity: 0.6;
  position: absolute;
  top: 80px;
  z-index: 2;
  left: ${props => props.pixels}px;
  animation-name: falldown;
  animation-duration: 4s;
`
